.room-container {
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
.room {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 64px);
    background-color: #1c1c1e;
  }

  .react-time-picker__clock {
    display: none !important;
  }

  .react-time-picker__wrapper , .react-date-picker__wrapper {
    border: 0 !important;
  }

  .react-time-picker__inputGroup__input {
    min-width: 0.75rem !important;
  }

  .react-date-picker__calendar {
    z-index:11;
  }

  .react-calendar__tile--active, .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
    background: #2F077F !important;
    color: #fff;
  }

  .react-calendar__tile:disabled {
    background: #f8f8ff;
    color: #ccc;
  }
